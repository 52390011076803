import React from "react";
import { useState } from "react";

export interface ContactInformation
{
  name: string;
  nameComponents?: string[];
  organization?: string;
  title?: string;
  phone?: { type?: string, value: string }[];
  email?: { type?: string, value: string }[];
  website?: { type?: string, value: string }[];
  address?: { type?: string, value: string }[];
  note?: string;
}

interface Properties {
    card: ContactInformation;
    onCardChange: (card: ContactInformation) => void;
}

export function mangleName(name: string): string[]
{
    const parts = name.split(" ");
    const nameComponents = [parts[parts.length-1], ...parts.slice(0, parts.length - 1)];
    while (nameComponents.length < 5)
        nameComponents.push("");
    return nameComponents;
}

const VCardEditor = (props: Properties) => {
    const { card, onCardChange } = props;

    const [advancedEditor, setAdvancedEditor] = useState(false);

    const set = <T extends keyof ContactInformation,>(property: T, value: ContactInformation[T]) =>
        onCardChange(Object.assign({}, card, { [property]: value }));
    const setName = (name: string) => onCardChange(Object.assign({}, card, { name, nameComponents: mangleName(name) }));
    const setArrayProp = <T extends "phone" | "email" | "website" | "address",>(property: T, index: number, value: { type?: string, value: string }) =>
        set(property, [...(card[property]?.slice(0, index) ?? []), value, ...(card[property]?.slice(index+1) ?? [])]);
    const removeArrayProp = (property: "phone" | "email" | "website" | "address", index: number) =>
        set(property, [...(card[property]?.slice(0, index) ?? []), ...(card[property]?.slice(index+1) ?? [])]);

    return (
        <div className="VCardEditor">
            <h2 className="h5 mb-4">Contact Info</h2>
            <div className="mb-3">
                <label className="form-label" htmlFor="name">Name</label>
                <input name="name" type="text" className="form-control" autoComplete="name"
                    value={card.name} onChange={e => setName(e.target.value)} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="company">Company</label>
                <input name="company" type="text" className="form-control" autoComplete="organization" value={card.organization ?? ""} onChange={e => set("organization", e.target.value)} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="title">Title</label>
                <input name="title" type="text" className="form-control" autoComplete="organization-title" value={card.title ?? ""} onChange={e => set("title", e.target.value)} />
            </div>
            {!advancedEditor && <>
                <div className="mb-3">
                    <label className="form-label" htmlFor="phone">Phone</label>
                    <input name="tel" type="tel" inputMode="tel" className="form-control" autoComplete="tel work" value={card.phone?.[0]?.value ?? ""} onChange={e => setArrayProp("phone", 0, { type: card.phone?.[0]?.type, value: e.target.value })} />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="email">Email</label>
                    <input name="email" type="email" inputMode="email" className="form-control" autoComplete="email work" value={card.email?.[0]?.value ?? ""} onChange={e => setArrayProp("email", 0, { type: card.email?.[0]?.type, value: e.target.value })} />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="website">Website</label>
                    <input name="website" type="url" className="form-control" autoComplete="website work" autoCorrect="off" autoCapitalize="off" value={card.website?.[0]?.value ?? ""} onChange={e => setArrayProp("website", 0, { type: card.website?.[0]?.type, value: e.target.value })} />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="address">Address</label>
                    <textarea name="address" className="form-control" autoComplete="address work" value={card.address?.[0]?.value ?? ""} onChange={e => setArrayProp("address", 0, { type: card.address?.[0]?.type, value: e.target.value })}></textarea>
                </div>

                <div>Need more? Try the <span className="link" onClick={() => setAdvancedEditor(true)}>advanced editor</span>.</div>
            </>}
            {advancedEditor && <>
                <div className="mb-3">
                    <label className="form-label" htmlFor="name">Phone</label>
                    {card.phone?.map((phone, index) => (
                        <div key={`phone-input-${index}`} className="d-flex mb-1" style={{ gap: "0.5rem", alignItems: "center" }}>
                            <select className="form-control" style={{ width: 125 }} value={phone.type} onChange={e => setArrayProp("phone", index, { type: e.target.value, value: phone.value })}>
                                <option value="main">Main</option>
                                <option value="cell">Cell</option>
                                <option value="home">Home</option>
                                <option value="work">Work</option>
                                <option value="other">Other</option>
                            </select>
                            <input style={{ flexGrow: 2 }} name="tel" type="tel" inputMode="tel" className="form-control" autoComplete="tel work" value={phone.value ?? ""} onChange={e => setArrayProp("phone", index, { type: phone.type, value: e.target.value })} />
                            <button type="button" className="btn btn-light" onClick={() => removeArrayProp("phone", index)}>&times;</button>
                        </div>
                    ))}
                    <div className="link" style={{ textAlign: "right" }} onClick={() => onCardChange(Object.assign({}, card, { phone: [...(card.phone ?? []), { type: "", value: "" }] }))}>+ Add Phone</div>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="name">Email</label>
                    {card.email?.map((email, index) => (
                        <div key={`email-input-${index}`} className="d-flex mb-1" style={{ gap: "0.5rem" }}>
                            <select className="form-control" style={{ width: 125 }} value={email.type} onChange={e => setArrayProp("email", index, { type: e.target.value, value: email.value })}>
                                <option value="home">Home</option>
                                <option value="work">Work</option>
                                <option value="other">Other</option>
                            </select>
                            <input style={{ flexGrow: 1 }} name="email" type="email" inputMode="email" className="form-control" autoComplete="email work" autoCorrect="off" autoCapitalize="off" value={email.value ?? ""} onChange={e => setArrayProp("email", index, { type: email.type, value: e.target.value })} />
                            <button type="button" className="btn btn-light" onClick={() => removeArrayProp("email", index)}>&times;</button>
                        </div>
                    ))}
                    <div className="link" style={{ textAlign: "right" }} onClick={() => onCardChange(Object.assign({}, card, { email: [...(card.email ?? []), { type: "", value: "" }] }))}>+ Add Email</div>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="name">Website</label>
                    {card.website?.map((website, index) => (
                        <div key={`website-input-${index}`} className="d-flex mb-1" style={{ gap: "0.5rem" }}>
                            <select className="form-control" style={{ width: 125 }} value={website.type} onChange={e => setArrayProp("website", index, { type: e.target.value, value: website.value })}>
                                <option value="home">Home</option>
                                <option value="work">Work</option>
                                <option value="other">Other</option>
                            </select>
                            <input style={{ flexGrow: 1 }} name="website" type="url" className="form-control" autoComplete="website work" autoCorrect="off" autoCapitalize="off" value={website.value ?? ""} onChange={e => setArrayProp("website", index, { type: website.type, value: e.target.value })} />
                            <button type="button" className="btn btn-light" onClick={() => removeArrayProp("website", index)}>&times;</button>
                        </div>
                    ))}
                    <div className="link" style={{ textAlign: "right" }} onClick={() => onCardChange(Object.assign({}, card, { website: [...(card.website ?? []), { type: "", value: "" }] }))}>+ Add Website</div>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="address">Address</label>
                    {card.address?.map((address, index) => (
                        <div key={`address-input-${index}`} className="d-flex mb-1" style={{ gap: "0.5rem", alignContent: "start", alignItems: "start" }}>
                            <select className="form-control" style={{ width: 125 }} value={address.type} onChange={e => setArrayProp("address", index, { type: e.target.value, value: address.value })}>
                                <option value="home">Home</option>
                                <option value="work">Work</option>
                                <option value="school">School</option>
                                <option value="other">Other</option>
                            </select>
                            <textarea name="address" className="form-control" autoComplete="address work" value={address.value ?? ""} onChange={e => setArrayProp("address", index, { type: address.type, value: e.target.value })}></textarea>
                            <button type="button" className="btn btn-light" onClick={() => removeArrayProp("address", index)}>&times;</button>
                        </div>
                    ))}
                    <div className="link" style={{ textAlign: "right" }} onClick={() => onCardChange(Object.assign({}, card, { address: [...(card.address ?? []), { type: "", value: "" }] }))}>+ Add Address</div>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="note">Note</label>
                    <textarea name="note" className="form-control" value={card.note ?? ""} onChange={e => set("note", e.target.value)}></textarea>
                </div>
            </>}
        </div>
    );
};

export default React.memo(VCardEditor);
