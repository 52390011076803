import React from "react";

const MarketingSpiel = () => (
    <div className="MarketingSpiel">
        <div className="row mb-5">
            <div className="col-lg-8 col-xl-6 offset-lg-2 offset-xl-3">
                <div className="MarketingSpiel-Card">
                    <h2 className="h4">Don't Make Contacts Type In Your Business Card!</h2>
                    <p>
                        After a networking event, nobody likes sitting at their computer and typing all of the business
                        cards they received. <strong>You can stand out from the pack</strong>: make a QR code that
                        people can scan to add to their contacts.
                    </p>
                    <p>
                        Enter your contact information, choose a pre-set background or upload your own, and
                        set the resultant image to your phone's lock screen or photo storage. When you give someone your
                        business card, also offer to let them scan your phone.
                    </p>
                    <p>
                        The whole process takes place on your device &mdash; your information is never sent to us or
                        anyone else. Easy as cabbage!
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default React.memo(MarketingSpiel);