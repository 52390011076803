import { useMemo, useRef, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import vcf from "vcf";

import VCardEditor, { ContactInformation, mangleName } from './components/VCardEditor';
import BackgroundPicker from './components/BackgroundPicker';

import renderImage from './image_rendering/renderImage';
import { useFilePicker } from 'use-file-picker';
import { Route, Routes } from 'react-router';
import TermsOfService from './components/TermsOfService';
import { useNavigate } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy';
import MarketingSpiel from './components/MarketingSpiel';

import './App.css';
import logo from './logo.svg';
import logoWhite from './logoWhite.svg';

const DefaultBackgrounds = [
  `${process.env.PUBLIC_URL}/backgrounds/cabbage.jpg`,
  `${process.env.PUBLIC_URL}/backgrounds/sand.jpg`,
  `${process.env.PUBLIC_URL}/backgrounds/waves.jpg`,
  `${process.env.PUBLIC_URL}/backgrounds/flowers.jpg`,
  `${process.env.PUBLIC_URL}/backgrounds/city.jpg`,
  `${process.env.PUBLIC_URL}/backgrounds/circuit.jpg`,
];

const IsIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const IsAndroid = /Android/i.test(navigator.userAgent);

function App() {
  const [card, setCard] = useState<ContactInformation>({ name: "" });
  const [backgroundIndex, setBackgroundIndex] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showShareInstructions, setShowShareInstructions] = useState(false);

  const navigate = useNavigate();

  const [, rawVCard] = useMemo(() => {
    const x = new vcf();
    x.add("version", "3.0");
    x.add("n", (card.nameComponents ?? mangleName(card.name)).join(";"));
    x.add("fn", card.name);
    card.organization && x.add("org", card.organization);
    card.title && x.add("title", card.title);
    card.note && x.add("note", card.note.replace("\n", ";"));

    card.phone?.forEach(({ type, value }) => value && x.add("tel", value, type ? { type } : undefined));
    card.email?.forEach(({ type, value }) => value && x.add("email", value, type ? { type } : undefined));
    card.website?.forEach(({ type, value }) => value && x.add("url", value, type ? { type } : undefined));
    card.address?.forEach(({ type, value }) => value && x.add("adr", value.replace("\n", ";"), type ? { type } : undefined));

    return [x, x.toString("3.0")];
  }, [card]);
  const isBlank = !card.name && !card.organization && !card.title && !card.note
    && !card.phone?.filter(x => !!x).length && !card.email?.filter(x => !!x).length
    && !card.website?.filter(x => !!x).length && !card.address?.filter(x => !!x).length;

  const renderingCanvas = useRef<HTMLCanvasElement>(null);
  const qrCanvasContainer = useRef<HTMLDivElement>(null);
  const backgroundImageElement = useRef<HTMLImageElement>(null);
  const downloadLinkElement = useRef<HTMLAnchorElement>(null);

  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: ["*.png", "*.jpg", "*.jpeg", "*.gif", "*.tif", "*.tiff"],
    multiple: true,
    readAs: "DataURL",
  });

  const onDownload = async (useShare: boolean) => {
    setIsDownloading(true);

    try {
      const canvas = renderingCanvas.current;
      const img = backgroundImageElement.current;
      if (!canvas || !img) {
        setErrorMessage("Could not render image in browser.");
        return;
      }

      const qrCanvas = qrCanvasContainer.current?.querySelector("canvas");

      const file = await renderImage(canvas, img, qrCanvas ?? null);

      if ((navigator as any).canShare) {
        const byteString = window.atob(file.split(",")[1]);
        const mimeString = file.split(",")[0].split(":")[1].split(";")[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i);

        if (useShare && (IsIOS || IsAndroid)) {
          const files = [new File([ab], `${card.name || "Background"}.png`, { type: mimeString })];

          if (navigator.canShare({ files })) {
            setShowShareInstructions(true);
            await navigator.share({
              files,
              title: "Your New Background",
              text: "Tap \"Save to Files\" to save to your files, find the file and add it to your photo library, then set your background.",
            });
            return;
          }
        }
      }
      
      downloadLinkElement.current?.setAttribute("href", file);
      downloadLinkElement.current?.click();
    }
    finally {
      setIsDownloading(false);
    }
  };

  const backgroundOptions = DefaultBackgrounds.concat(filesContent.map(f => f.content));

  return (
    <div className="App h-100 d-flex flex-column">
      <div style={{ display: "none" }}>
        <img ref={backgroundImageElement} src={backgroundOptions[backgroundIndex]} crossOrigin="anonymous" alt="Background image compositor" />
        <canvas ref={renderingCanvas} width={1284} height={2778} style={{ border: "blue 1px solid" }} />
        <a ref={downloadLinkElement} download="background.png" href="#"></a>
      </div>

      {showShareInstructions && <div className="ShareInstructionsPopover" onClick={() => setShowShareInstructions(false)}>
        <div className="mb-3">Here's how to set your new background:</div>
        <ol>
          <li>Tap <strong>Save to Files</strong> to save to your files.</li>
          <li>Go to <strong>Files</strong> and add the file to your photo library.</li>
          <li>Go <strong>Settings</strong> and choose a new wallpaper.</li>
        </ol>
        <div>Sadly, iOS 15 does not let you add the file directly to your photo library. &#x1F614;</div>
      </div>}

      <div className="container mt-4 mb-4">
        <header className="mb-4" onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
          <h1 className="display-5" style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <img src={logo} className="App-logo" alt="logo" width="50px" height="50px" />
            CBGS<small className="text-muted" style={{ marginLeft: "-5px" }}>.us</small>
          </h1>
          <div style={{ fontSize: "125%" }}>(<strong>C</strong>ontact <strong>B</strong>ack<strong>G</strong>round<strong>S</strong>)</div>
        </header>

        {errorMessage && <div className="alert alert-error">{errorMessage}</div>}

        <Routes>
          <Route path="/" element={<>
            <MarketingSpiel />

            <div className="d-block d-lg-flex" style={{ gap: "1rem" }}>
              <div style={{ flex: 1 }}>
                <div className="card mb-3">
                  <div className="card-body">
                    <VCardEditor card={card} onCardChange={newCard => setCard(newCard)} />
                  </div>
                </div>
              </div>
              <div className="display-5 pt-3 pb-4 pt-lg-5 text-center">+</div>
              <div style={{ flex: 1 }}>
                <div className="card mb-3">
                  <div className="card-body">
                    <BackgroundPicker
                      backgrounds={backgroundOptions}
                      selectedIndex={backgroundIndex}
                      onSelectedIndexChange={newIndex => setBackgroundIndex(newIndex)}
                      openFileSelector={openFileSelector}
                    />
                  </div>
                </div>
              </div>
              <div className="display-5 pt-3 pb-4 pt-lg-5 text-center">=</div>
              <div style={{ flex: 1 }}>
                <div className="card mb-3">
                  <div className="card-body" style={{ borderBottom: "#ddd 1px solid" }}>
                    <h2 className="h5 card-title">New Phone Background</h2>
                  </div>
                  <div className="card-body" style={{
                    backgroundImage: `url(${backgroundOptions[backgroundIndex]})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: 600
                  }}>
                    <div className="d-flex">
                      <div style={{ flexGrow: 1 }}></div>
                      <div style={{ padding: 15, background: "white", marginTop: 175, borderRadius: 10 }}>
                        {isBlank
                          ? <div style={{ height: 200, width: 200, textAlign: "center", paddingTop: 90 }}>Fill in some contact info</div>
                          : <div ref={qrCanvasContainer}>
                            {rawVCard.length < 1500
                              ? <QRCodeCanvas value={rawVCard} size={500} style={{ height: 200, width: 200 }} />
                              : <div style={{ height: 200, width: 200, textAlign: "center", paddingTop: 90 }}>Too large for QR code</div>}
                          </div>}
                      </div>
                      <div style={{ flexGrow: 1 }}></div>
                    </div>
                  </div>

                  <div className="card-body">
                    <button className="btn btn-lg btn-primary w-100" disabled={isBlank || isDownloading} onClick={() => onDownload(true)}>
                      {isDownloading
                        ? <>
                          <div style={{ display: "inline-block", position: "relative", top: -2 }}>
                            <img className="spin" src={logoWhite} height={20} style={{ display: "inline-block" }} />
                          </div>
                          &nbsp;Downloading&hellip;
                        </>
                        : <>Download</>}
                    </button>

                    {IsIOS && <div className="mt-3">
                      <p>When the Share pane pops up, choose <strong>Save to Files</strong> to save the image to your files, then find it and choose <strong>Save to Photos</strong>, then set your background. Yes, this used to be easier but changed in iOS 15.</p>
                      <p className="mb-0">Not working? Try{' '}
                      <span className="link" onClick={() => onDownload(false)}>downloading as a file</span> instead.</p>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </>}>
          </Route>
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </div>

      <footer className="text-muted small footer mt-auto mb-3">
        <div className="container">

          Web application &copy; 2022 &ndash; 2023 CBGS
          <span className="mx-2 text-muted">&middot;</span>
          <a href="/terms">Terms of Service</a>
          <span className="mx-2 text-muted">&middot;</span>
          <a href="/privacy">Privacy Policy</a>
          <br />
          Photographs from <a href="https://unsplash.com">Unsplash</a> by{' '}
          <a href="https://unsplash.com/@anniespratt">Annie Spratt</a>,{' '}
          <a href="https://unsplash.com/@brandonrae">Brandon Rae</a>,{' '}
          <a href="https://unsplash.com/@christinadera">Christina Deravedisian</a>,{' '}
          <a href="https://unsplash.com/@matthewhenry">Matthew Henry</a>,{' '}
          <a href="https://unsplash.com/@lazycreekimages">Michael Dziedzic</a>, and{' '}
          <a href="https://unsplash.com/@natskov">Natasha Skov</a>.

        </div>
      </footer>
    </div>
  );
}

export default App;
