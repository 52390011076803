import React from "react";
import "./BackgroundPicker.css";

interface Properties {
    backgrounds: string[];
    selectedIndex: number;
    onSelectedIndexChange: (i: number) => void;
    openFileSelector: () => void;
}

function BackgroundPicker(props: Properties) {

    return (
        <div className="BackgroundPicker">
            <h2 className="h5 card-title mb-4">Background</h2>

            <div className="BackgroundOptionsContainer mb-3">
                {props.backgrounds.map((background, index) => 
                    <BackgroundOption
                      key={`background-${index}`}
                      background={background}
                      selected={props.selectedIndex === index}
                      onSelect={() => props.onSelectedIndexChange(index)} />
                )}
            </div>

            <button className="btn btn-outline-primary w-100" onClick={() => props.openFileSelector()}>Choose Your Own</button>
        </div>
    );
}

const BackgroundOption = React.memo((props: { background: string; selected: boolean; onSelect: () => void }) => {
    return (
        <div
          className={props.selected ? "BackgroundOption BackgroundOption-Selected" : "BackgroundOption"}
          style={{ backgroundImage: `url(${props.background})` }}
          onClick={() => props.onSelect()}
        >
            <div className="check">&#x2713;</div>
        </div>
    );
});

export default React.memo(BackgroundPicker);