import React from "react";

const TermsOfService = () => (
    <div className="TermsOfService">
            <p>
            Welcome to CBGS! Do read through the following Terms of Service (the "Terms") carefully before
            using
            the CBGS platform (the "Website") and any other services (collectively the "Services"). By using
            our Website, you are agreeing to our Terms. The Terms are here for you so that you are aware of your
            legal rights when using our Services.
        </p>

        <h3>Agreement Terms</h3>
        <p>
            The Terms will be treated as a legally binding agreement made between CBGS (referred to
            individually or collectively as "CBGS", "we", "us", or "our") and you, the Website visitor, and/or
            registered user ("you"). We reserve the right at any time to:
        </p>
        <ul>
            <li>Change the Terms;</li>
            <li>
                Change the Website, including eliminating or discontinuing any content on or feature of the Website,
                restricting the hours of availability, or limiting the amount of use permitted; or
            </li>
            <li>
                Change any fees or charges for use of the Website, including instituting new or increased fees or
                charges for the use of the Website or Services or any feature thereof.
            </li>
        </ul>
        <p>
            Any changes we make will be effective immediately upon notice, which we may provide by any means,
            including, without limitation, posting on the Website or via electronic mail. Your use of the Website
            after such notice will be deemed acceptance of such changes. Be sure to review these Terms periodically
            to ensure familiarity with the most current version. Upon our request, you agree to sign a
            non-electronic version of this agreement.
        </p>
        <p>You must be at least 18 years of age to use the Website.</p>

        <h3>Ownership; Proprietary Notices</h3>
        <p><strong>&copy; 2022 CBGS</strong></p>
        <p>
            The Website, including all pages within and all code related thereto, is the property of CBGS. No
            portion of the materials or code on these pages or anywhere on the Website may be reprinted or
            republished (other than as is necessary to view the page on your monitor) in any form without our
            express written permission.
        </p>
        <p>
            All trademarks and/or service marks used in this Website are the trademarks and/or service marks of
            their respective owners.
        </p>
        <p>
            The Website is owned and operated by us in conjunction with others pursuant to contractual arrangements.
            Unauthorized use of the materials on the Website may violate copyright, trademark, patent, and other
            laws
            and is prohibited. You acknowledge that you do not acquire any ownership rights by using the Website.
        </p>

        <h3>Warranty Information; Disclaimer</h3>
        <p>
            THIS WEBSITE, INCLUDING ANY CONTENT OR INFORMATION CONTAINED WITHIN IT OR ANY WEBSITE-RELATED SERVICE,
            OR ANY PRODUCT OR SERVICE LICENSED, PURCHASED, OR OTHERWISE MADE AVAILABLE THROUGH THE WEBSITE, IS
            PROVIDED "AS IS" WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. TO THE
            FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, CBGS AND ITS SUBSIDIARIES, AFFILIATES,
            SUPPLIERS, SPONSORS, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
            TO, THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT NEITHER CBGS, ITS SUBSIDIARIES, AFFILIATES, SPONSORS, SUPPLIERS NOR AGENTS MAKES
            ANY REPRESENTATION AS TO THE RESULTS TO BE OBTAINED FROM USE OF THE WEBSITE OR THE WEBSITE-RELATED
            SERVICES.
        </p>

        <p>
            CBGS AND ITS SUBSIDIARIES, AFFILIATES, SUPPLIERS, AND AGENTS DO NOT WARRANT THAT YOUR USE OF THE
            WEBSITE OR THE SOFTWARE WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE, THAT DEFECTS WILL BE CORRECTED, OR
            THAT THE WEBSITE (OR THE SERVER(S) ON WHICH IT IS HOSTED) OR SOFTWARE ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS. YOU ACKNOWLEDGE THAT YOU ARE RESPONSIBLE FOR OBTAINING AND MAINTAINING ALL
            TELEPHONE, COMPUTER HARDWARE, AND OTHER EQUIPMENT NEEDED TO ACCESS AND USE THE WEBSITE AND ALL CHARGES
            RELATED THERETO. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THE WEBSITE AND THE SOFTWARE
            AND YOUR RELIANCE THEREON. NO OPINION, ADVICE, OR STATEMENT, WHETHER MADE ON THE WEBSITE OR OTHERWISE,
            SHALL CREATE ANY WARRANTY.
            <strong>
                YOUR USE OF THE WEBSITE AND ANY MATERIALS PROVIDED THROUGH THE WEBSITE ARE ENTIRELY AT YOUR OWN
                RISK.
            </strong>
        </p>

        <p>
            You acknowledge that the Website could include inaccuracies or errors or materials that violate these
            Terms. Additionally, you acknowledge that unauthorized additions, deletions, and alterations could be
            made by third parties to the Website. Although we attempt to ensure the integrity and the accuracy of
            the Website, we make no guarantees whatsoever as to the Website's completeness or correctness. In the
            event that such a situation arises, please contact us at
            {' '}<a href="mailto:support@cbgs.us">support@cbgs.us</a>{' '}
            with, if possible, a description of the material to be checked and the location (URL) where such
            material can be found on the Website, as well as information sufficient to enable us to contact you. We
            will try to address your concerns as soon as reasonably practicable. For copyright infringement claims,
            see the section on "Claims of Copyright Infringement".
        </p>

        <p>
            Because user authentication on the Internet is difficult, the Company cannot and does not confirm that
            users are who they claim to be. Because we do not and cannot be involved in user-to-user transactions or
            control the behavior of the Website's users, in the event that you have a dispute with one or more
            Website users, you release CBGS (and its subsidiaries, affiliates, agents, and employees) from
            all claims, demands, and damages (actual and consequential, direct and indirect) of any kind and nature,
            known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way
            related to such disputes. You acknowledge that there may be certain risks, including but not limited to
            the risk of physical harm and of dealing with strangers, underage persons, or people acting under false
            pretenses associated with pursuing relationships begun on the Internet. You assume all risks related to
            dealing with other users with whom you have had contact through the Website.
        </p>

        <p>
            You acknowledge and agree that you are solely responsible for the form, content, and accuracy of any
            material you post to the Website. We neither warrant nor guarantee that any material on the site is
            accurate, nor do we necessarily check material that you supply for accuracy.
        </p>

        <h3>Limitation of Liability</h3>
        <p>
            NEITHER CBGS NOR ITS SUBSIDIARIES, AFFILIATES, SUPPLIERS, ADVERTISERS, AGENTS, OR SPONSORS ARE
            RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR
            OTHER DAMAGES UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHER THEORY ARISING OUT OF OR
            RELATING IN ANY WAY TO THE WEBSITE AND/OR CONTENT CONTAINED ON THE WEBSITE, OR ANY PRODUCT OR SERVICE
            PURCHASED THROUGH THE WEBSITE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE AND/OR CONTENT
            CONTAINED WITHIN THE WEBSITE IS TO STOP USING THE WEBSITE. THE SOLE AND EXCLUSIVE MAXIMUM LIABILITY TO
            CBGS FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT, INCLUDING, WITHOUT
            LIMITATION, NEGLIGENCE, OR OTHERWISE) SHALL BE THE TOTAL AMOUNT PAID BY YOU, IF ANY, TO ACCESS THE
            WEBSITE.
        </p>

        <h3>Acknowledgement</h3>
        <p>
            This Website may contain links to other websites operated by third parties ("Linked Sites"). We neither
            control nor endorse such other websites nor have we reviewed or approved any content that appears on the
            Linked Sites. You acknowledge that when you click on a link to visit a Linked Site, a frame may appear
            that contains our logo, advertisements, and/or other content selected by us. You acknowledge that we
            neither endorse nor are affiliated with the Linked Sites and are not responsible for any content that
            appears on the Linked Sites. You also acknowledge that the owner of the Linked Site neither endorses nor
            is affiliated with CBGS.
        </p>

        <h3>Availability; Indemnification</h3>
        <p>
            CBGS reserves the right to limit the availability of the Website and/or the provision of any
            product or service described thereon to any person, geographic area, or jurisdiction it so desires at
            any time and in our sole discretion and to limit the quantities of any such product or service that we
            provide.
        </p>

        <p>
            You agree to indemnify, defend and hold us, our affiliates, sponsors, officers, directors, employees,
            agents, and representatives harmless from and against any and all claims, damages, losses, costs
            (including reasonable attorneys' fees), or other expenses that arise directly or indirectly out of or
            from (a) your breach of this Agreement, (b) your violation of the Code of Conduct, and/or (c) your
            activities in connection with the Webite or Website-related services.
        </p>

        <p style={{ fontWeight: "bold" }}>
            In consideration of your use of the Services, you expressly agree to waive, release and hold CBGS,
            our employees, officers, and agents harmless from and against any and all claims or causes of action,
            and you hereby agree to voluntarily give up or waive any right that you may otherwise have to bring
            legal action against CBGS, our employees, officers, and agents for wrongful death, personal injury,
            property damage, reputational damage and other causes of action known or unknown. You agree to hold
            CBGS, our employees, officers, and agents harmless from and against any losses, liabilities, costs,
            damages, or expenses which result from your participation in the Services, regardless of whether or not
            caused in whole or part by the fault of any of CBGS, our employees, officers, and agents.
        </p>

        <h3>Code of Conduct</h3>
        <p>
            While using the Website or Website-related services, you agree not to do any of the following without
            our prior written authorization:
        </p>

        <ul>
            <li>
                Knowingly post any inaccurate, untimely, stale, incomplete, or misleading information.
            </li>
            <li>
                Resell or make available to any person not expressly licensed, whether via phone, fax, e-mail, mail,
                or any other medium, any of the Services, or any information obtained therefrom.
            </li>
            <li>
                Engage in spamming, flooding, soliciting, or mass marketing via e-mail, direct mail, telephone, or
                otherwise to users of the Website.
            </li>
            <li>
                Delete or revise any material posted by any other person or entity.
            </li>
            <li>
                Restrict or inhibit any other user from using and enjoying the Website and services, including,
                without limitation, by means of "hacking" or defacing any portion of the Website.
            </li>
            <li>
                Post or transmit (a) any incomplete, false or inaccurate information (b) any content or information
                that is unlawful, fraudulent, threatening, abusive, libelous, defamatory, obscene or otherwise
                objectionable or harmful, or that infringes on our or any third party's intellectual property or
                other rights; (c) any advertisements, solicitations, chain letters, pyramid schemes, investment
                opportunities, or other unsolicited commercial communication; (d) any information or software that
                contains a virus, worm, Trojan horse or other harmful or disruptive component; (e) any trade secret
                of any third party; or (f) any material, nonpublic information about companies without the
                authorization to do so.
            </li>
            <li>
                Use the Website for any unlawful commercial, research, or information gathering purposes.
            </li>
            <li>
                Communicate, send, or place unsolicited e-mail, telephone calls, mailings, or other contacts to
                posting individuals and entities.
            </li>
            <li>
                Modify, adapt, sublicense, translate, sell, reverse engineer, decompile or disassemble any portion
                of the Website.
            </li>
            <li>
                Remove any copyright, trademark, or other proprietary rights notices contained in the Website.
            </li>
            <li>
                "Frame" or "mirror" any part of the Website.
            </li>
            <li>
                Use any robot, spider, site search/retrieval application, or other manual or automatic device or
                process to retrieve, index, "data mine," or in any way reproduce or circumvent the navigational
                structure or presentation of the Website or its contents.
            </li>
        </ul>
        <p>
            You agree to comply with all applicable laws, rules, and regulations in connection with your use of the
            Website and Services.
        </p>
        <p>
            CBGS has no obligation to monitor the Website. However, you acknowledge and agree that CBGS
            has the right (but not the obligation) to monitor the Website and the materials you transmit or post, to
            alter or remove any such materials (including, without limitation, any posting to the Website), and to
            disclose such materials and the circumstances surrounding their transmission to any third party in order
            to operate the Website properly, to protect itself, its sponsors, its registered users, and visitors,
            and to comply with legal obligations or governmental requests. CBGS reserves the right to refuse to
            post or to remove any information or materials, in whole or in part, that it deems in its sole
            discretion to be unacceptable, offensive, or in violation of this Agreement. CBGS also reserves the
            right to prohibit any user who, in our sole discretion, violates the Code of Conduct or other terms of
            this Agreement from using the Site and related services. Such prohibition may occur without notice to
            the user.
        </p>

        <h3>Logo and Trademark Submissions</h3>
        <p>
            You hereby grant CBGS permission to display your logo, trademarks, and company name on the
            Website and in press and other public releases or filings. PLEASE NOTE THAT YOU RETAIN OWNERSHIP OF ANY
            COPYRIGHTS, TRADEMARKS, AND SERVICE MARKS IN ANY CONTENT YOU SUBMIT.
        </p>

        <h3>Registration; Use of Secure Areas and Passwords</h3>
        <p>
            Some areas of the Website may require you to register with us. When and if you register, you agree to
            (a) provide accurate, current, and complete information about yourself as prompted by our registration
            form (including your e-mail address and phone number) and (b) to maintain and update your information
            (including your e-mail address and phone number) to keep it accurate, current, and complete. You
            acknowledge that should any information provided by you be found to be untrue, inaccurate, not current,
            or incomplete, we reserve the right to terminate this Agreement with you and your use of the Website.
        </p>
        <p>
            As part of the registration process, you will be asked to select a password. You will be responsible for
            the confidentiality and use of your password and agree not to distribute, transfer or resell your use of
            or access to the Website to any third party. If more than one individual wishes to use a single password
            belonging to a registered user, such registered user must request permission from CBGS in writing,
            it being understood that CBGS shall be under no obligation to approve any such request. YOU ARE
            SOLELY AND ENTIRELY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF YOUR PASSWORD AND SOLELY AND
            ENTIRELY RESPONSIBLE FOR ANY AND ALL ACTIVITIES THAT ARE CONDUCTED THROUGH YOUR ACCOUNT.
        </p>

        <h3>Miscellaneous</h3>
        <p>
            This Agreement is entered into in the State of Colorado and shall be governed by and construed in
            accordance with the laws of the State of Colorado, exclusive of its choice of law rules. Each party to
            this Agreement hereby submits to the exclusive jurisdiction of the state and federal courts sitting in
            Denver County in the State of Colorado for any dispute arising under or in connection with this
            Agreement, the Website or any Website-related services, and waives any jurisdictional, venue, or
            inconvenient forum objections to such courts. In any action to enforce this Agreement, the prevailing
            party will be entitled to costs and attorneys' fees. In the event that any of the provisions of this
            Agreement shall be held by a court or other tribunal of competent jurisdiction to be unenforceable, such
            provisions shall be limited or eliminated to the minimum extent necessary so that this Agreement shall
            otherwise remain in full force and effect and enforceable. Failure of any party to insist upon strict
            compliance with any of the terms and conditions of this Agreement shall not be deemed a waiver or
            relinquishment of any similar right or power at any subsequent time.
        </p>

        <p>
            This Agreement constitutes the entire agreement between the parties hereto pertaining to the subject
            matter hereof, and any and all written or oral agreements heretofore existing between the parties hereto
            are expressly canceled. This Agreement is not assignable, transferable, or sub-licensable by you except
            with prior written consent. Any heading, caption, or section title contained in this Agreement is
            inserted only as a matter of convenience and in no way defines or explains any section or provision
            hereof. CBGS reserves the right in its sole discretion to terminate the use of the Website by a
            user at any time.
        </p>
    </div>
);

export default React.memo(TermsOfService);
