type Rectangle = { x: number, y: number, width: number, height: number };

/**
 * Composites a barcode onto a background image using `canvas` and `img` elements, and returns a data URI for the user
 * to download.
 */
async function renderImage(canvas: HTMLCanvasElement, image: HTMLImageElement, qrCanvas: HTMLCanvasElement | null): Promise<string> {
    const ctx = canvas.getContext("2d");
    if (!ctx)
        throw new Error("Unable to get context");

    drawImageToCover(canvas, ctx, image);

    const qrRectangle = {
        x: canvas.width / 6,
        y: canvas.height - canvas.height / 2 - canvas.width / 3,
        width: 2 * canvas.width / 3,
        height: 2 * canvas.width / 3,
    };

    backgroundPath(ctx, qrRectangle);
    ctx.fillStyle = "white";
    ctx.fill();

    if (qrCanvas)
        await copyQRCode(qrCanvas, ctx, {
            x: qrRectangle.x + canvas.width / 20,
            y: qrRectangle.y + canvas.width / 20,
            width: qrRectangle.width - canvas.width / 10,
            height: qrRectangle.height - canvas.width / 10,
        });

    return canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
}

/**
 * Draw an image to completely cover the canvas, using the given image element.
 * @param canvas 
 * @param img 
 */
function drawImageToCover(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    img: HTMLImageElement
): void {
    const r = Math.min(canvas.width / img.width, canvas.height / img.height);
    let nw = img.width * r, nh = img.height * r;
    let cx = 1, cy = 1, cw = 1, ch = 1, ar = 1;

    if (nw < canvas.width)
      ar = canvas.width / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < canvas.height)
      ar = canvas.height / nh;
    
    nw *= ar;
    nh *= ar;

    // Calculate the source rectangle
    cw = img.width / (nw / canvas.width);
    ch = img.height / (nh / canvas.height);

    cx = (img.width - cw) * 0.5;
    cy = (img.height - ch) * 0.5;

    cx = Math.max(0, Math.min(img.width, cx));
    cy = Math.max(0, Math.min(img.height, cy));

    context.drawImage(img, cx, cy, cw, ch, 0, 0, canvas.width, canvas.height);
}

/**
 * Draws a white rectangle with rounded corners on the canvas in the given location.
 * @param context
 * @param rectangle 
 * @param radius
 */
function backgroundPath(
    context: CanvasRenderingContext2D,
    { x, y, width, height }: Rectangle,
    radius: number = 50
) {
    context.beginPath();
    context.moveTo(x + radius, y);
    context.arcTo(x + width, y, x + width, y + height, radius);
    context.arcTo(x + width, y + height, x, y + height, radius);
    context.arcTo(x, y + height, x, y, radius);
    context.arcTo(x, y, x + width, y, radius);
    context.closePath();
}

function copyQRCode(
    qrCanvas: HTMLCanvasElement,
    destinationContext: CanvasRenderingContext2D,
    qrRectangle: Rectangle
): Promise<void> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = function () {
            destinationContext?.drawImage(image, qrRectangle.x, qrRectangle.y, qrRectangle.width, qrRectangle.height);
            resolve();
        };
        image.onabort = reject;
        image.onerror = reject;
        image.src = qrCanvas.toDataURL("image/png");
    });
}

export default renderImage;